import React, { useState, useEffect } from "react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import "./styles.scss";

const SimpleHero = props => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const [windowWidth, setWindowWidth] = useState<number>(0);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => setWindowWidth(window.innerWidth);

      setWindowWidth(window.innerWidth);

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const shouldDisplayDesktopImage =
    props._rawDesktopImage?.asset && (windowWidth > 768 || !props._rawMobileImage?.asset);
  const shouldDisplayMobileImage =
    props._rawMobileImage?.asset && (windowWidth <= 768 || !props._rawDesktopImage?.asset);

  return (
    <section className="rx-simple-hero">
      {shouldDisplayDesktopImage && (
        <picture className="desktop-image">
          <source
            media={"(min-width: 1200px)"}
            srcSet={
              urlBuilder
                .image(props._rawDesktopImage)
                .auto("format")
                .fit("crop")
                .quality(80)
                .width(1440)
                .height(648)
                .format("webp")
                .url() as string
            }
          />
          <source
            media={"(min-width: 992px)"}
            srcSet={
              urlBuilder
                .image(props._rawDesktopImage)
                .auto("format")
                .fit("crop")
                .quality(80)
                .width(1280)
                .height(720)
                .format("webp")
                .url() as string
            }
          />
          <source
            media={"(min-width: 768px)"}
            srcSet={
              urlBuilder
                .image(props._rawDesktopImage)
                .auto("format")
                .fit("crop")
                .quality(80)
                .width(1024)
                .height(576)
                .format("webp")
                .url() as string
            }
          />
          <img
            src={
              urlBuilder
                .image(props._rawDesktopImage)
                .auto("format")
                .fit("crop")
                .quality(80)
                .width(640)
                .height(360)
                .format("webp")
                .url() as string
            }
            alt={props._rawDesktopImage?.alt}
          />
        </picture>
      )}

      {shouldDisplayMobileImage && (
        <picture className="mobile-image">
          <source
            media={"(min-width: 1200px)"}
            srcSet={
              urlBuilder
                .image(props._rawMobileImage)
                .auto("format")
                .fit("crop")
                .quality(80)
                .width(1440)
                .height(1080)
                .format("webp")
                .url() as string
            }
          />
          <source
            media={"(min-width: 992px)"}
            srcSet={
              urlBuilder
                .image(props._rawMobileImage)
                .auto("format")
                .fit("crop")
                .quality(80)
                .width(1280)
                .height(960)
                .format("webp")
                .url() as string
            }
          />
          <source
            media={"(min-width: 768px)"}
            srcSet={
              urlBuilder
                .image(props._rawMobileImage)
                .auto("format")
                .fit("crop")
                .quality(80)
                .width(1024)
                .height(768)
                .format("webp")
                .url() as string
            }
          />
          <img
            src={
              urlBuilder
                .image(props._rawMobileImage)
                .auto("format")
                .fit("crop")
                .quality(80)
                .width(640)
                .height(480)
                .format("webp")
                .url() as string
            }
            alt={props._rawMobileImage?.alt}
          />
        </picture>
      )}
    </section>
  );
};

export default SimpleHero;
